import { registerApplication, start } from 'single-spa'
import {
  constructApplications,
  constructLayoutEngine,
  constructRoutes
} from 'single-spa-layout'

import microfrontendLayout from './microfrontend-layout.html'

const routes = constructRoutes(microfrontendLayout)

window.global = window //add this to your app.
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name)
  }
})
const layoutEngine = constructLayoutEngine({ routes, applications })

applications.forEach(registerApplication)
layoutEngine.activate()
start()
